@font-face {
	font-family: 'Geist';
	font-weight: normal;
	font-style: normal;
	src: url(./fonts/Geist-Regular.woff2) format('opentype');
}

@font-face {
	font-family: 'Geist';
	font-weight: bold;
	font-style: normal;
	src: url(./fonts/Geist-Bold.woff2) format('opentype');
}

@font-face {
	font-family: 'Infiltrator';
	font-weight: normal;
	font-style: normal;
	src: url(./fonts/Infiltrator-Regular.woff2) format('opentype');
}

html, body {
    margin: 0;
    padding: 0;
}

body {
	background-color: #0f0f0f;
	box-sizing: border-box;
	color: #fff;
    font-family: "Geist", sans-serif;
    font-size: 21px;
    line-height: 29px;
}